<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">
      {{
        document.isTemplate ? t("headings.template") : t("headings.document")
      }}
    </h1>
    <breadcrumb class="mt-3 mb-4" />
    <div v-if="!waiting" class="card p-4">
      <h3 class="mb-3">{{ document.name }}</h3>
      <read-only
        v-if="document.content"
        :content="JSON.parse(document.content)"
      />
      <hr class="my-3 border-gray-300" />
      <div class="flex justify-end">
        <form-button
          :command="() => router.push(`/documents/${document.id}/edit`)"
          :disabled="waiting"
          id="edit-button"
          label="edit"
          :textVariant="company.primaryText"
          type="submit"
          :variant="company.primary"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import ReadOnly from "../components/editor/ReadOnly.vue";
import FormButton from "../components/form/FormButton.vue";
export default {
  components: { Breadcrumb, FormButton, ReadOnly },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const document = computed(() => store.state.document.document);

    const waiting = computed(() => {
      if (store.state.company.waiting) return true;
      if (store.state.document.waiting) return true;
      return false;
    });

    onMounted(() => {
      if (!document.value.id || document.value.id != route.params.documentId) {
        store.dispatch("document/getDocument", route.params.documentId);
      }
    });

    return {
      company: computed(() => store.state.company.company),
      document,
      router,
      t,
      waiting,
    };
  },
};
</script>
